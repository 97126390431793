export function simpleNotification(successMessage, timeout) {
  return ` \
   <div data-toast-target="notification" \
     x-data="{ show: true }" \ 
     x-show="show" x-cloak \
     x-transition:enter="tw-transition tw-transition-opacity tw-ease-out tw-duration-300" \
     x-transition:enter-start="tw-opacity-0 tw-transform tw--translate-y-5" \
     x-transition:enter-end="tw-opacity-100 tw-transform tw--translate-y-0" \
     x-transition:leave="tw-transition tw-ease-in tw-duration-100" \
     x-transition:leave-end="tw-opacity-0 tw-transform tw--translate-y-5" \
     x-init="setTimeout(() => { show = false; }, ${timeout});" \
     class="tw-border-l-8 tw-border-primary-500 tw-max-w-sm tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-ring-1 tw-ring-black tw-ring-opacity-12 tw-overflow-hidden dark:tw-bg-dark-10 dark:tw-ring-white dark:tw-ring-opacity-12"> \
    <div class="tw-p-4"> \
      <div class="tw-flex tw-items-center"> \
        <div class="tw-w-0 tw-flex-1 tw-flex tw-justify-between"> \
          <p class="tw-w-0 tw-flex-1 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-opacity-87 dark:tw-text-white"> \
            ${successMessage} \
          </p> \
        </div> \
        <div class="tw-ml-4 tw-flex-shrink-0 tw-flex"> \
          <div type="button" x-on:click="show = false" \
            class="tw-inline-flex tw-text-gray-400 hover:tw-text-gray-500 tw-text-lg dark:tw-text-white dark:tw-text-opacity-60 dark:hover:tw-text-opacity-87"> \
            <I class="far fa-times"></I> \
          </div> \
        </div> \
      </div> \
    </div> \
   </div> \
  `
}

export function notificationWithCallToAction(actionPath, successMessage, hyperlinkText, timeout) {
  return ` \
    <div data-toast-target="notification" \ 
      x-data="{ show: true }" \ 
      x-show="show" \ 
      x-cloak \ 
      x-transition:enter="tw-transition tw-transition-opacity tw-ease-out tw-duration-300" \
      x-transition:enter-start="tw-opacity-0 tw-transform tw--translate-y-5" \
      x-transition:enter-end="tw-opacity-100 tw-transform tw--translate-y-0" \
      x-transition:leave="tw-transition tw-ease-in tw-duration-100" \
      x-transition:leave-end="tw-opacity-0 tw-transform tw--translate-y-5" \
      x-init="setTimeout(() => { show = false; }, ${timeout});" \
      class="tw-border-l-8 tw-border-primary-500 tw-max-w-sm tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-ring-1 tw-ring-black tw-ring-opacity-12 tw-overflow-hidden dark:tw-bg-dark-10 dark:tw-ring-white dark:tw-ring-opacity-5"> \
      <div class="tw-p-4"> \
        <div class="tw-flex tw-items-center"> \
          <div class="tw-w-0 tw-flex-1 tw-pt-0.5"> \
            <p class="tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-opacity-87 dark:tw-text-white"> \
              ${successMessage} \
            </p> \
            <div type="button" class="tw-text-sm tw-font-medium tw-underline tw-text-primary-500">
              <a href="${actionPath}" target="_blank" rel="noopener"> \
                 ${hyperlinkText} \
              </a> \
            </div> \
          </div> \
          <div class="tw-ml-4 tw-flex-shrink-0 tw-flex"> \
             <div type="button" x-on:click="show = false" class="tw-inline-flex tw-text-gray-400 hover:tw-text-gray-500 tw-text-lg dark:tw-text-white dark:tw-text-opacity-60 dark:hover:tw-text-opacity-87"> \
              <I class="far fa-times"></I> \
            </div> \
          </div> \
        </div> \
      </div> \
    </div> \
  `
}

export function simpleNotificationToast(
  successMessage,
  timeout = 2000
) {
  return simpleNotification(
    successMessage,
    timeout
  );
}

export function withCallToActionNotificationToast(
  actionPath,
  successMessage,
  hyperlinkText,
  timeout = 2000
) {
  return notificationWithCallToAction(
    actionPath,
    successMessage,
    hyperlinkText,
    timeout
  )
}

