import { loadScript } from "../../util/load_script";
import { getActiveCurrency } from "../../util/currency";
import { EventCurrencyChanged } from "../../events";

const TRADING_VIEW_CHART_JS_URL = "https://s3.tradingview.com/tv.js";
const TRUSTED_MARKETS_MAP = {
  binance: true,
  bitfinex: true,
  bitstamp: true,
  bittrex: true,
  hitbtc: true,
  kraken: true,
  poloniex: true,
  bithumb: true,
  bitflyer: true,
  ftx: true,
};

let tvScriptLoaded = false;
let tvChartCurrency = null;

export const renderTradingViewChart = async (element, coinApiSymbol) => {
  // Load TradingView script once only.
  if (!tvScriptLoaded) {
    await loadScript(true).url(TRADING_VIEW_CHART_JS_URL);

    window.addEventListener(EventCurrencyChanged, () => {
      renderTradingViewChart(element, coinApiSymbol);
    });

    tvScriptLoaded = true;
  }

  // If the currency is the same, no need to rerender.
  if (tvChartCurrency === getActiveCurrency()) {
    return;
  }

  tvChartCurrency = getActiveCurrency().toUpperCase();

  fetch(`https://api.coingecko.com/api/v3/coins/${coinApiSymbol}?localization=false`)
    .then(response => response.json())
    .then(res => {
      const tickersInSupportedExchanges = [];
      const tickersWithTargetCurrency = [];

      // Try to pick (1) tickers from trusted exchange (2) ticker with matching target as active currency
      for (const t of res.tickers) {
        const marketName = t?.market?.name?.toLowerCase();
        if (!marketName || !TRUSTED_MARKETS_MAP[marketName]) {
          continue;
        }

        tickersInSupportedExchanges.push(t);

        // Note: Allow USDT to be considered if its USD.
        if (t.target === "USDT" && tvChartCurrency === "USD") {
          tickersWithTargetCurrency.push(t);
          break;
        } else if (t.target === tvChartCurrency) {
          tickersWithTargetCurrency.push(t);
          break;
        }
      }

      // Prioritize selecting ticker with target active currency.
      const ticker = tickersWithTargetCurrency[0] || tickersInSupportedExchanges[0];
      if (!ticker) {
        element.innerHTML = `<div class="dark:tw-text-moon-50">${I18n.t("charts.tradingview_unavailable")}</div>`;
        return;
      }

      // Render TradingView widget into the target element.
      new TradingView.widget({
        autosize: true,
        symbol: `${ticker.market.name.toUpperCase()}:${ticker.base}${ticker.target}`,
        interval: "60",
        theme: "Dark",
        style: "1",
        locale: I18n.locale,
        enable_publishing: false,
        withdateranges: true,
        hide_side_toolbar: false,
        allow_symbol_change: true,
        container_id: element.id
      });
    });
};
