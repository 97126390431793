// This file contains definitions to load a set of scripts and CSS files asynchronously.
// This is usually used within the connect() method of your Stimulus controller.
//
// This ensures that required scripts and CSS files are only loaded when the controller
// functionality requires it, and reduces the global chunk size.
//
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import

export const loadFlipDown = () => {
  if (window._moduleFlipDown) {
    return Promise.resolve(window._moduleFlipDown);
  }

  return Promise
    .all([
      import("../flipdown").catch(error => {
        console.error("Failed to load FlipDown module:", error);
        return null;
      }),
      import("../stylesheets_v2/components/flipdown.scss").catch(error => {
        console.error("Failed to load FlipDown stylesheet:", error);
      })
    ])
    .then(res => {
      const [flipDownModule, _] = res;
      if (!flipDownModule) {
        throw new Error("FlipDown module is unavailable due to a load error.");
      }

      window._moduleFlipDown = flipDownModule.default;

      return window._moduleFlipDown;
    })
    .catch(error => {
      console.error("An error occurred while loading FlipDown:", error);
      return null;
    });
};


export const loadFlatpickr = () => Promise
  .all([
    import("flatpickr"),
    import("../stylesheets_v2/components/flatpickr.scss")
  ])
  .then(res => {
    return res[0].default;
  });


export const loadHighcharts = () => {
  if (window._moduleHighcharts) {
    return Promise.resolve(window._moduleHighcharts);
  }

  return Promise
    .all([
      import("highcharts/highstock"),
      import("highcharts/modules/boost"),
      import("highcharts/modules/no-data-to-display"),
      import("highcharts/modules/exporting"),
      import("../stylesheets_v2/components/highcharts.scss")
    ])
    .then(res => {
      const Highcharts = res[0];
      const Boost = res[1].default;
      const NoData = res[2].default;
      const Exporting = res[3].default;

      Boost(Highcharts);
      NoData(Highcharts);
      Exporting(Highcharts);

      Highcharts.setOptions({
        lang: {
          months: I18n.t("charts.months"),
          shortMonths: I18n.t("charts.short_months"),
          noData: I18n.t("charts.no_data")
        }
      });

      window._exporterLoaded = false;
      window._moduleHighcharts = Highcharts;
      return window._moduleHighcharts;
    });
};
