import toggleChartTypeButtons from "util/toggle_chart_type_buttons";

export const API_ENDPOINTS = {
  production: "https://api.coingecko.com/api/v3",
  staging: process.env.ROOT_URL + "/api/v3",
  development: "http://localhost:3000/api/v3"
};

export const ASSET_ENDPOINTS = {
  production: "https://assets.coingecko.com",
  staging: "https://assets-staging.coingecko.com",
  development: "https://assets-development.coingecko.com"
};

export const AD_INTEREST_KEYS = {
  coins: "adTargetingCoins",
  categories: "adTargetingCategories",
  chains: "adTargetingChains",
  developer: "adTargetingDeveloper",
}

export const STRICT_COOKIE = "C0001";
export const PERF_COOKIE = "C0002";
export const FUNC_COOKIE = "C0003";
export const TARGET_COOKIE = "C0004";

export const HIGHCHARTS_DATE_FORMAT = "%a %d %b %Y, %H:%M:%S";

export const USER_UUID_KEY = "userUuid";

export const HIGHCHARTS_COLORS = [
  "#7cb5ec",
  "#c27ded",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#cccccc",
];

export const chartTranslations = () => {
  let translations = I18n.t("charts");
  let chartLangObj = {
    downloadJPEG: translations.download_jpeg_image,
    downloadPDF: translations.download_pdf_document,
    downloadPNG: translations.download_png_image,
    downloadSVG: translations.download_svg_vector_image,
    exitFullscreen: translations.exit_from_full_screen,
    viewFullscreen: translations.view_in_full_screen,
    printChart: translations.print_chart,
  };

  return chartLangObj;
}

export const CONSENT_CONFIG_COOKIE_NAME = "OptanonConsent";

export const stockChartOptions = (elementId, hideFullscreenMenuItem = false) => ({
  time: { useUTC: false },
  chart: {
    panning: false,
    marginTop: 50,
    renderTo: `${elementId}`,
    styledMode: true,
    zoomType: 'x',
    className: 'cg-highcharts'
  },
  rangeSelector: {
    buttons: [],
    inputPosition: {
        align: "right",
        x: 0,
        y: -20
    },
  },
  scrollbar: {
    enabled: false
  },
  defs: {
    gradient0: {
        tagName: 'linearGradient',
        id: 'gradient-standard',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [{
            tagName: 'stop',
            offset: 0,
            style: "stop-color:#7cb5ec; stop-opacity:0.3"
        }, {
            tagName: 'stop',
            offset: 1,
            style: "stop-color:#7cb5ec; stop-opacity:0"
        }]
    },
    gradient1: {
        tagName: 'linearGradient',
        id: 'gradient-danger',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
        children: [{
            tagName: 'stop',
            offset: 0,
            style: "stop-color:#e15241; stop-opacity:0.3"
        }, {
            tagName: 'stop',
            offset: 1,
            style: "stop-color:#e15241; stop-opacity:0"
        }]
    },
    gradient2: {
      tagName: 'linearGradient',
      id: 'gradient-green',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [{
          tagName: 'stop',
          offset: 0,
          style: "stop-color:#4eaf0a; stop-opacity:0.3"
      }, {
          tagName: 'stop',
          offset: 1,
          style: "stop-color:#4eaf0a; stop-opacity:0"
      }]
  }
},
  exporting: {
    buttons: {
      customButton: {
        className: 'highcharts-button-linear highcharts-button-active',
        text: I18n.t("charts.linear"),
        onclick: function() {
          this.yAxis.forEach((chart, _) => {
            chart.update({ type: 'linear' });
          });

          toggleChartTypeButtons();
        },
        y: -10
      },
      customButton2: {
        className: 'highcharts-button-log',
        text: I18n.t("charts.logarithmic"),
        onclick: function() {
          this.yAxis.forEach((chart, _) => {
            chart.update({ type: 'logarithmic' });
          });

          toggleChartTypeButtons();
        },
        y: -10
      },
      contextButton: {
        ...(hideFullscreenMenuItem ? { menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG', 'downloadPDF'] } : {}),
        y: -10
      }
    },
    chartOptions: {
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false
          }
        }
      }
    },
    fallbackToExportServer: false
  },
  credits: {
    enabled: false
  },
});

export default {
  API_ENDPOINTS
};
