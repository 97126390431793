import { Controller } from "stimulus";
import { throttle } from "../../util";

export default class extends Controller {
  static targets = [
    "contentRow", "contentContainer", "totalContent", "readMoreButton",

    // Sticky price
    "stickyNftPrice", "staticNftPrice",

    // Navigation tabs
    "nftTabs", "navAnchor", "securityContent", "tokenomicsContent",
  ];

  connect() {
    this._handleStickyNavigation();
    this._handleNavigationHighlight();

    window.addEventListener("scroll", throttle(this._handleStickyNavigation.bind(this), 50));
    window.addEventListener("scroll", throttle(this._handleNavigationHighlight.bind(this), 100));
    window.addEventListener("resize", throttle(this._resizeContentContainer.bind(this), 25));

    this._resizeContentContainer();
    this._showHideReadMoreButton();
  }

  _showHideReadMoreButton() {
    const visibleContentHeight = this.contentContainerTarget.getBoundingClientRect().height
    const totalContentHeight = this.totalContentTarget.getBoundingClientRect().height

    if ((totalContentHeight - visibleContentHeight) > 12) {
      this.readMoreButtonTarget.classList.remove("tw-hidden");
    }
  }

  _resizeContentContainer() {
    if (!this.hasContentContainerTarget) {
      return;
    }

    if (window.innerWidth < 1200) {
      this.contentContainerTarget.style.maxHeight = null;
      return;
    }

    const offset = 120;
    const height = this.contentRowTarget.getBoundingClientRect().height - offset;

    this.contentContainerTarget.style.maxHeight = height + "px";
  }

  _handleStickyNavigation() {
    if (this.staticNftPriceTarget.getBoundingClientRect().top >= -20) {
      this.nftTabsTarget.classList.remove("!tw-fixed", "tw-h-12");
      this.stickyNftPriceTarget.classList.add("tw-hidden");
    } else {
      this.nftTabsTarget.classList.add("!tw-fixed", "tw-h-12");
      this.stickyNftPriceTarget.classList.remove("tw-hidden");
    }
  }

  _handleNavigationHighlight() {
    let previousElement = this.nftTabsTarget.querySelector(".selected");
    if (previousElement) {
      previousElement.classList.remove("selected");
    }

    // Prevents the first item from being unhighlighted before meeting scroll threshold.
    if (window.scrollY <= 300) {
      const firstAnchor = this.navAnchorTargets[0].dataset.key;
      this.nftTabsTarget.querySelector(`#${firstAnchor}`)?.classList?.add("selected");
      return;
    }

    let nearestElement = this.navAnchorTargets.filter(x => x.offsetParent !== null && x.getBoundingClientRect().top <= 200).reverse()[0];
    if (nearestElement) {
      this.nftTabsTarget.querySelector(`#${nearestElement.dataset.key}`)?.classList?.add("selected");
    }
  }

  scrollToAnchor(e) {
    let target = this.navAnchorTargets.filter(x => x.dataset.key === e.currentTarget.id)[0];
    if (!target) {
      return;
    }

    const top = target.getBoundingClientRect().top + window.scrollY - 75;
    window.scrollTo({ top, behavior: "smooth" });
  }
}
