function toggleChartTypeButtons() {
  const classNames = [
    '.highcharts-button-log',
    '.highcharts-button-linear',
  ];

  classNames.forEach((name, _) => {
    document
      .querySelector(name)
      .classList
      .toggle('highcharts-button-active');
  });
}

export default toggleChartTypeButtons;
